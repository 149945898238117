input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.scrollbar::-webkit-scrollbar {
  display: none;
}
/* ::-webkit-scrollbar {
  color: black;
} */
.ant-btn-primary.ant-btn-background-ghost {
  color: #1677ff;
  background: transparent;
  border-color: #1677ff;
  box-shadow: none;
}
.ant-btn-primary {
  background-color: #1677ff;
}
.align-left .swiper-wrapper {
  justify-content: flex-start; /* Align slides to the left */
}

.align-left .swiper-slide {
  display: flex;
  justify-content: flex-start; /* Align content inside each slide to the left */
}

.swiper {
  display: grid !important;
}

/* .input-bg-disabled:disabled {
  background-color: #fff;
} */

/* .swiper .swiper-initialized .swiper-horizontal {
  z-index: 0;
} */
