.cardTop:hover > .cardBottom {
  transform: translateY(-30px);
  background-color: rgba(255, 255, 255, 0.74);
}
.cardBottom {
  transition: 0.3s;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.zoom-effect {
  padding: 0.3rem;
  transition: all 0.3s;
}
.zoom-effect:hover {
  padding: 0;
}
.swiper-wrapper {
  justify-content: center;
}
