.segment > .subModal {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.3s linear;
}
.segment {
  transition: all 0.3s;
}
.segment:hover {
  /* padding: 3rem; */
  /* background-color: white; */
  /* color: black; */
}
.segment:hover > .subModal {
  visibility: visible;
  opacity: 1;
  /* margin: 5rem 0; */
}

.subModal {
  transition: all 0.3s;
  position: absolute;
  /* left: 4.5rem; */
  /* top: 1.5rem; */
}

/* dropdown-end .dropdown-content {
  inset-inline-end: -6rem;
} */

@media only screen and (max-width: 442px) {
  .dropdown-end .dropdown-content {
  }
  #cart {
    inset-inline-end: -4rem;
  }
  #wishlist {
    inset-inline-end: -6rem;
  }
}
