:where(.css-dev-only-do-not-override-usln0u).ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: transparent;
  border: none;
  border-bottom: 0;
  border-radius: 0;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0;
}

.filter-list {
  display: none;
}
.filter-icon {
  display: block;
}
@media only screen and (min-width: 1440px) {
  .filter-list {
    display: block;
  }
  .filter-icon {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1029px) {
  .filter-drawer {
    margin-top: 5rem;
  }
}
.swiper-slide {
  margin: 0 !important;
  padding: 0 !important;
}
